import React, { useState, useEffect } from 'react';
import Slider from './Slider';

function Events() {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const updatePositions = () => {
      const screenWidth = window.innerWidth; // Viewport width
      const boxWidth = (screenWidth * 0.1); // Fixed width of each image
      const boxHeight = '52vh'; // Responsive height
      const totalBoxes = 5; // Number of boxes in a row
      const gap = 0; // Gap between boxes

      // 1. Calculate total width occupied by the group of 4 items
      const totalGroupWidth = (boxWidth * totalBoxes) + (gap * (totalBoxes - 1));

      // 2. Calculate STARTING X position to center the group
      const startX = (screenWidth - totalGroupWidth) / 5; // Center the whole group
      const fristGroupStartX = (screenWidth - totalGroupWidth) / 3.5; // Center the whole group

      // 3. Define positions for the 4 centered items
      const positions = [
        // Row 1 - Centered Group
        { id: 1, img: './img/event-main/event-header/asset-2.png', x: fristGroupStartX, y: 150 },
        { id: 2, img: './img/event-main/event-header/asset-5.png', x: fristGroupStartX + (boxWidth + gap), y: 150 },
        { id: 3, img: './img/event-main/event-header/asset-4.png', x: fristGroupStartX + 2 * (boxWidth + gap), y: 150 },
        { id: 4, img: './img/event-main/event-header/asset-9.png', x: fristGroupStartX + 3 * (boxWidth + gap), y: 150 },

        // Row 2 - Centered Group
        { id: 5, img: './img/event-main/event-header/asset-8.png', x: startX, y: 400 },
        { id: 6, img: './img/event-main/event-header/asset-6.png', x: startX + (boxWidth + gap), y: 400 },
        { id: 7, img: './img/event-main/event-header/asset-7.png', x: startX + 2 * (boxWidth + gap), y: 400 },
        { id: 8, img: './img/event-main/event-header/asset-3.png', x: startX + 3 * (boxWidth + gap), y: 400 },
        { id: 9, img: './img/event-main/event-header/asset-1.png', x: startX + 4 * (boxWidth + gap), y: 400 },

      ];

      setBoxes(positions);
    };

    updatePositions(); // Set positions initially
    window.addEventListener('resize', updatePositions); // Adjust on resize

    return () => window.removeEventListener('resize', updatePositions); // Cleanup
  }, []);
  // const [boxes, setBoxes] = useState([
  //   { id: 1, img: './img/event-main/event-header/asset-2.png', x: 250, y: 150 },
  //   { id: 2, img: './img/event-main/event-header/asset-5.png', x: 400, y: 150 },
  //   { id: 3, img: './img/event-main/event-header/asset-4.png', x: 550, y: 150 },
  //   { id: 4, img: './img/event-main/event-header/asset-9.png', x: 700, y: 150 },

  //   { id: 5, img: './img/event-main/event-header/asset-8.png', x: 100, y: 400 },
  //   { id: 6, img: './img/event-main/event-header/asset-6.png', x: 300, y: 400 },
  //   { id: 7, img: './img/event-main/event-header/asset-7.png', x: 500, y: 400 },
  //   { id: 8, img: './img/event-main/event-header/asset-3.png', x: 700, y: 400 },
  //   { id: 9, img: './img/event-main/event-header/asset-1.png', x: 900, y: 400 },
  // ]);


  const [isDragging, setIsDragging] = useState(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e, id) => {
    console.log(id);
    const box = boxes.find((box) => box.id === id);
    setIsDragging(id);
    setOffset({ x: e.clientX - box.x, y: e.clientY - box.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging !== null) {
      const updatedBoxes = boxes.map((box) => {
        if (box.id === isDragging) {
          const x = e.clientX - offset.x;
          const y = e.clientY - offset.y;
          return { ...box, x, y };
        }
        return box;
      });
      setBoxes(updatedBoxes);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(null);
  };


  const slides = [
    './img/event-main/asset1.jpg',
    './img/event-main/asset2.jpg',
    './img/event-main/asset3.jpg',
    './img/event-main/asset4.jpg',
    './img/event-main/asset5.jpg',
    './img/event-main/asset6.jpg',
    './img/event-main/asset7.jpg',
    './img/event-main/asset8.jpg',
    './img/event-main/asset9.jpg',
  ];

  return (
    <>
      <div
        className='d-md-none d-sm-none d-none d-lg-block'
        style={{
          height: '100vh',
          position: 'relative',
          top: '20vh',
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}

      >
        <h1 className='text-center text-uppercase' style={{ lineHeight: .9 }}>Fashion <br /> Show Looks</h1>
        {boxes.map((box) => (
          <div
            key={box.id}
            className='hover-target'
            style={{
              position: 'absolute',
              width: '10%',
              height: '52vh',
              left: `calc(50% + ${box.x - 500}px)`,
              top: `calc(50% + ${box.y - 500}px)`,
              backgroundImage: `url(${box.img})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            onMouseDown={(e) => handleMouseDown(e, box.id)}
          />
        ))}
      </div>

      <div className='container-fluid p-5 mx-auto mt-3 ' style={{ background: '#000' }} >
        <Slider slides={slides} />
      </div>
      <div className='container-fluid p-5 mx-auto' id="featuredIn" style={{ background: '#000' }}>
        <h2 className='text-center mb-3'>FEATURED</h2>
        <div className='row' style={{ justifyContent: 'center' }}>
          <div className='col-md-4 col-lg-4 col-12 image-wrapper'>
            <a target='_blank' href="https://fashionweekonline.com/milan-fashion-week-showcase-of-emerging-talent-and-accessory-excellence" className='hover-target'>
              <img alt="FWO" src='./img/event-main/publications/fwo.jpg' className='img-fluid' />
            </a>
          </div>
          <div target='_blank' className='col-md-4 col-lg-4 col-12 image-wrapper' >
            <a href="https://elle.metropolitan.si/moda/novice3/flying-solo-teden-mode-v-milanu-modni-dodatki/" className='hover-target'>
              <img alt='ELLE' src='./img/event-main/publications/elle.jpg' className='img-fluid' />
            </a>
          </div>
        </div>
      </div>
    </>

  );
}



export default Events;
